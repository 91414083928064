import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    path: String,
    body: Object,
    method: String
  }

  static targets = ["source"]

  #fetch () {
    fetch(this.pathValue, {
      method: this.methodValue,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify(this.bodyValue)
    })
  }

  #observeElement(callback) {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "attributes" &&
            mutation.attributeName === "connected" &&
            this.sourceTarget.hasAttribute("connected")
          ) {
          callback();
        }
      });
    });

    observer.observe(this.sourceTarget, {
      attributes: true
    });
  }

  connect()  {
    if (this.sourceTarget.hasAttribute("connected")) {
      this.#fetch();
    } else {
      this.#observeElement(() => {
        this.#fetch();
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "errorsContainer",
    "errors",
    "cardNumber",
    "cardExpiry",
    "cardCvv",
    "firstName",
    "lastName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zip",
    "surchargeAccepted",
    "surchargeAcceptedError",
    "submitButton",
    "form"
  ]

  static values = {
    betterErrors: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    let style;

    if (this.betterErrorsValue) {
      style = "font-size: 0.875rem !important; width: 100%; line-height: 1.5rem;"
    } else {
      style = "font-size: 1.1rem; width: 100%; font-weight: 450;"
    }

    this.staxJs = new StaxJs(this.data.get("public-key"), {
      number: {
        id: 'card-number',     // the html id of the div you want to contain the credit card number field
        placeholder: "Enter card number",    // the placeholder the field should contain
        style,
        type: 'text',   // the input type (optional)
        format: 'prettyFormat'    // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
      },
      cvv: {
        id: "card-cvv",    // the html id of the div you want to contain the cvv field
        placeholder: "000",    // the placeholder the field should contain
        style,
        type: 'number'    // the input type (optional)
      }
    })

    this.staxJs.showCardForm()

    this.cardExpiryTarget.addEventListener("input", (event) => {
      if (this.cardExpiryTarget.value.length == 2 && event.inputType === "insertText") {
        this.cardExpiryTarget.value += "/"
      }
    })
  }

  submit(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    this.clearErrors()

    if (this.isSurchargeAcceptanceRequired()) {
      this.surchargeAcceptedTarget.parentNode.classList.add("field_with_errors")
      this.surchargeAcceptedErrorTarget.classList.remove("hidden")
      this.submitButtonTarget.disabled = false
      return
    }

    this.staxJs
      .tokenize(this.extraDetails())
      .then((response) => {
        this.addResultToHiddenInput(response.id);
        this.formTarget.submit()
      })
      .catch((err) => {
        if (this.betterErrorsValue) {
          this.handleErrors(err)
        } else {
          this.handleStaxErrors(err)
        }
      });
  }

  handleErrors(error) {
    // Sometimes error object is an array instead of an object. An example of this is when
    // the public key is invalid, then the 1 item in the error array will be 'invalid_hosted_payments_token'
    if (error instanceof Array) {
      return;
    }

    this.element.querySelectorAll(".field_with_errors").forEach((el) => {
      el.classList.remove("ring-red-500")
      el.classList.add("ring-gray-500")
    })

    this.element.querySelectorAll(".error").forEach(el => el.remove())

    let expiryErrors = error.fieldErrors.filter(e => e.field === "month" || e.field === "year")
    let cardErrors = error.fieldErrors.filter(e => e.field === "card-number")

    if (expiryErrors.length > 0) {
      this.addErrorsToElement(this.cardExpiryTarget, expiryErrors)
    }

    if (cardErrors.length > 0) {
      this.addErrorsToElement(this.cardNumberTarget, cardErrors)
    }

    if (this.surchargeAcceptedTarget.checked) {
      this.submitButtonTarget.disabled = false
    }
  }

  addErrorsToElement(element, errors) {
    element.parentElement.classList.add("field_with_errors")
    element.parentElement.classList.remove("ring-gray-300")
    element.parentElement.classList.add("!ring-red-500")

    const errorText = errors.map(e => e.message).join(", ")
    const html = this.getHtmlForError(errorText)
    element.parentElement.insertAdjacentHTML("afterend", html)
  }

  getHtmlForError(text) {
    return `
    <p class="flex error text-xs text-red-600 px-2 py-1" style="margin-top: 0px !important">
      ${text}
    </p>`;
  }

  isSurchargeAcceptanceRequired() {
    // A boolean comes through the data attribute as a string ¯\_(ツ)_/¯
    return this.data.get("surcharge-enabled") === "true" && !this.surchargeAcceptedTarget.checked
  }

  handleStaxErrors(error) {
    if (error.fieldErrors) {
      error.fieldErrors.forEach((e) => this.showError(e.message))
    } else if (error.message) {
      this.showError(error.message)
    } else {
      this.showError(JSON.stringify(error))
    }
    this.errorsContainerTarget.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    })
  }

  extraDetails() {
    return {
      customer_id: this.data.get("customer-id"),
      firstname: this.firstNameTarget.value,
      lastname: this.lastNameTarget.value,
      method: "card",
      month: this.parseExpirationMonth(),
      year: "20" + this.parseExpirationYear(),
      address_1: this.addressLine1Target.value,
      address_2: this.addressLine2Target.value,
      address_city: this.cityTarget.value,
      address_state: this.stateTarget.value,
      address_zip: this.zipTarget.value,
      address_country: this.data.get("home-country"),
      // validate is optional and can be true or false.
      // determines whether or not stax.js does client-side validation.
      // the validation follows the sames rules as the api.
      // check the api documentation for more info:
      // https://staxpayments.com/api-documentation/
      validate: true,
    }
  }

  parseExpirationYear() {
    return this.cardExpiryTarget.value.split("/")[1]
  }

  parseExpirationMonth() {
    return this.cardExpiryTarget.value.split("/")[0]
  }

  showError(text) {
    this.errorsContainerTarget.classList.remove("hidden")
    this.submitButtonTarget.disabled = false

    let errorElement = document.createElement("p")
    errorElement.textContent = text
    this.errorsTarget.appendChild(errorElement)
  }

  clearErrors() {
    this.submitButtonTarget.disabled = true
    this.surchargeAcceptedErrorTarget.classList.add("hidden")
    this.errorsContainerTarget.classList.add("hidden")
    this.errorsTarget.innerHTML = ""
  }

  addResultToHiddenInput(result) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.name = "result";

    this.formTarget.appendChild(input);
    input.value = result
  }
}

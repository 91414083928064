import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {}
  static targets = ["submitButton"]

  toggleSubmitEnabled(event) {
    if (event.target.checked) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'address',
    'addressLine2',
    'city',
    'streetNumber',
    'route',
    'postalCode',
    'country',
    'county',
    'state',
    'shortState',
    'longitude',
    'latitude',
    'placeId'
  ]

  static values = {
    country: Array,
    setAddressToLine1: Boolean
  }

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    if (google !== undefined) {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);
    this.autocomplete.addListener('place_changed', this.placeChanged);
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace();
    const addressComponents = this.place.address_components;

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(addressComponents);

      this.setAddressComponents(formattedAddress);
    }

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry);
    }

    if (this.place.place_id !== undefined) {
      this.setPlaceID(this.place.place_id);
    }
  }

  setAddressComponents(address) {
    if (this.hasStreetNumberTarget) this.streetNumberTarget.value = address.street_number || '';
    if (this.hasRouteTarget) this.routeTarget.value = address.route || '';
    if (this.hasCityTarget) this.cityTarget.value = address.locality || '';
    if (this.hasCountyTarget) this.countyTarget.value = address.administrative_area_level_2 || '';
    if (this.hasStateTarget) this.stateTarget.value = address.administrative_area_level_1 || '';
    if (this.hasShortStateTarget) this.shortStateTarget.value = address.administrative_area_level_1_short_name || '';
    if (this.hasCountryTarget) this.countryTarget.value = address.country || '';
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = address.postal_code || '';

    // Reset address_line_2 if it exists
    if (this.hasAddressLine2Target) this.addressLine2Target.value = '';

    if (this.setAddressToLine1Value) {
      this.addressTarget.value = address.street_number + " " + address.route;
    }
  }

  setGeometry(geometry) {
    if (this.hasLongitudeTarget) this.longitudeTarget.value = geometry.location.lng().toString();
    if (this.hasLatitudeTarget) this.latitudeTarget.value = geometry.location.lat().toString();
  }

  setPlaceID(placeId) {
    if (this.hasPlaceIdTarget) this.placeIdTarget.value = placeId;
  }

  get autocompleteOptions() {
    return {
      fields: ['address_components', 'geometry'],
      componentRestrictions: {
        country: ['us']
      }
    };
  }

  preventSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {};

    addressComponents.forEach((component) => {
      const type = component.types[0];

      if (type === "administrative_area_level_1") {
        data["administrative_area_level_1_short_name"] = component.short_name;
      }

      data[type] = component.long_name;
    });

    return data;
  }
}

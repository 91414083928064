import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
      "errorsContainer",
      "errors",
      "accountNumber",
      "accountNumberConfirmation",
      "routingNumber",
      "submitButton",
      "form"
    ]

    connect() {
      this.staxJs = new StaxJs(this.data.get("public-key"), {})

      this.accountNumberConfirmationTarget
        .addEventListener("paste", e => e.preventDefault());
    }

    submit(e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      this.clearErrors()

      if (!this.accountNumbersMatch()) {
        this.showError("Account Number must match")
        return
      }

      this.staxJs
        .tokenize(this.extraDetails())
        .then((response) => {
          this.addResultToHiddenInput(response.id);
          this.formTarget.submit()
        })
        .catch((e) => this.handleStaxErrors(e));
    }

    /**
      * Stax.js Example Client Validation Error Objects
      * When account & routing number is populated, but routing number is invalid..
      * {
      *   "errors": ["Could not tokenize payment method: Routing number is invalid"]
      *  }
      *
      *  When account is blank, routing is valid or invalid...
      *  {
      *    "code": "logic_error",
      *    "message": "this field is is required (bank_account)",
      *    "fieldErrors": [{
      *        "field": "bank_account",
      *        "message": "this field is is required (bank_account)"
      *    }]
      *  }
      *
      *  When account and routing are blank...
      *  {
      *    "code": "logic_error",
      *    "message": "One or more fields failed validation.",
      *    "fieldErrors": [{
      *      "field": "bank_account",
      *      "message": "this field is is required (bank_account)"
      *    },
      *    {
      *      "field": "bank_routing",
      *      "message": "this field is is required (bank_routing)"
      *    }]
      *  }
      */
    handleStaxErrors(error) {
      this.submitButtonTarget.disabled = false

      if (error.fieldErrors) {
        error.fieldErrors.forEach(err => this.showError(err.message))
      } else if (error.errors) {
        this.showError(error.errors[0])
      } else {
        this.showError(JSON.stringify(error))
      }
      this.errorsContainerTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      })
    }

    extraDetails() {
      return {
        customer_id: this.data.get("customer-id"),
        person_name: this.data.get("full-name"),
        method: "bank",
        bank_type: "checking", //	required, "checking" or "savings"
        bank_holder_type: "personal", // required, "personal" or "business",
        bank_account: this.accountNumberTarget.value,	// required, string representing the bank account number
        bank_routing:	this.routingNumberTarget.value, // required, string representing the bank routing number (9 digits)
        // validate is optional and can be true or false.
        // determines whether or not stax.js does client-side validation.
        // the validation follows the sames rules as the api.
        // check the api documentation for more info:
        // https://staxpayments.com/api-documentation/
        validate: true,
      }
    }

    accountNumbersMatch() {
      return this.accountNumberTarget.value === this.accountNumberConfirmationTarget.value
    }

    showError(text) {
      this.errorsContainerTarget.classList.remove("hidden")
      this.submitButtonTarget.disabled = false

      let errorElement = document.createElement("p")
      errorElement.textContent = text
      this.errorsTarget.appendChild(errorElement)
    }

    clearErrors() {
      this.submitButtonTarget.disabled = true
      this.errorsContainerTarget.classList.add("hidden")
      this.errorsTarget.innerHTML = ""
    }

    addResultToHiddenInput(result) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.name = "result";

      this.formTarget.appendChild(input);
      input.value = result
    }
}
